<!-- 我的申报 详情 -->
<template>
  <div>
    <!--    <headtitle Otitle="我的申报" />-->
    <el-card class="box-card">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item><a @click="back">人才审核</a></el-breadcrumb-item>
        <el-breadcrumb-item>人才详情</el-breadcrumb-item>
      </el-breadcrumb>
    </el-card>

    <div class="hr-20"></div>

    <v-row>
      <v-col cols="9">
        <v-card flat>
          <v-card-text class="text-content">
            <el-form
              :model="personalInformation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%;"
            >
              <v-row>
                <v-col cols="3">
                  <span>姓名:</span>
                  {{ personalInformation.name }}
                </v-col>
                <v-col cols="3">
                  <span>出生年月:</span>
                  {{ personalInformation.birthday }}
                </v-col>
                <v-col cols="3">
                  <span>性别:</span>
                  <span
                    class="content"
                    v-html="personalInformation.sex === 1 ? '女' : '男'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>国籍:</span>
                  {{ personalInformation.citizenship }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="3"
                  v-if="personalInformation.citizenship === '中国'"
                >
                  <span>民族:</span>
                  {{ personalInformation.nation }}
                </v-col>
                <v-col cols="3">
                  <span>联系电话:</span>
                  {{ personalInformation.phone }}
                </v-col>
                <v-col cols="3">
                  <span>邮箱:</span>
                  {{ personalInformation.email }}
                </v-col>
                <v-col
                  cols="3"
                  v-if="personalInformation.citizenship === '中国'"
                >
                  <span>籍贯:</span>
                  {{ personalInformation.birthplace }}
                </v-col>
                <v-col cols="3">
                  <span>证件类型:</span>
                  {{ personalInformation.identityType }}
                </v-col>
                <v-col cols="3">
                  <span>证件编号:</span>
                  {{ personalInformation.identityCode }}
                </v-col>
                <v-col cols="3">
                  <span>证件开始有效日期:</span>
                  <span
                    class="content"
                    v-html="
                      personalInformation.startEffectiveDate
                        ? personalInformation.startEffectiveDate.substring(
                            0,
                            10
                          )
                        : personalInformation.startEffectiveDate
                    "
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>证件结束有效日期:</span>
                  <span
                    class="content"
                    v-html="
                      personalInformation.endEffectiveDate
                        ? personalInformation.endEffectiveDate.substring(0, 10)
                        : personalInformation.endEffectiveDate
                    "
                  ></span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <span>户籍住址:</span>
                  {{ personalInformation.addressed }}
                </v-col>
              </v-row>
            </el-form>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <!-- 工作履历 -->
        <v-card flat>
          <v-card-text class="text-content">
            <el-form
              :model="personalInformation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%;"
            >
              <v-row>
                <v-col cols="4">
                  <span>是否省外引进:</span>
                  {{ personalInformation.talentOccupation.rankLevel }}
                </v-col>
                <v-col cols="4">
                  <span>从事专业领域:</span>
                  {{ personalInformation.talentOccupation.skillCategory }}
                </v-col>
                <v-col cols="4">
                  <span>引进时间:</span>
                  <span
                    class="content"
                    v-html="
                      personalInformation.talentOccupation.introductionTime
                        ? personalInformation.talentOccupation.introductionTime.substring(
                            0,
                            10
                          )
                        : personalInformation.talentOccupation.introductionTime
                    "
                  ></span>
                </v-col>
              </v-row>
            </el-form>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <!-- 目前就职 -->
        <v-card flat>
          <v-card-text class="text-content">
            <el-form
              :model="personalInformation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%;"
            >
              <v-row>
                <v-col cols="3">
                  <span>目前供职公司:</span>
                  {{ personalInformation.enterpriseName }}
                </v-col>
                <v-col cols="3">
                  <span>所在单位职务:</span>
                  {{ personalInformation.talentOccupation.position }}
                </v-col>
                <v-col cols="3">
                  <span>入职时间:</span>
                  {{
                    personalInformation.talentOccupation.hireDate | formatDate
                  }}
                </v-col>
                <v-col cols="3">
                  <span>职称类别:</span>
                  {{ personalInformation.talentOccupation.rankCategory }}
                </v-col>
              </v-row>

              <v-row class="card-height">
                <div
                  class="file"
                  v-for="item in laborContractFile"
                  :key="item.id"
                >
                  <v-btn
                    depressed
                    class="see-file"
                    color="primary"
                    @click="getFile(item.path)"
                  >
                    {{ item.memo }}
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    fab
                    x-small
                    class="ml-1"
                    color="primary"
                  >
                    <v-icon @click="getDownloadFile(item)"
                      >arrow_downward</v-icon
                    >
                  </v-btn>
                </div>
                <div
                  class="file"
                  v-for="item in individualIncomeTax"
                  :key="item.id"
                >
                  <v-btn
                    depressed
                    class="see-file"
                    color="primary"
                    @click="getFile(item.path)"
                  >
                    {{ item.memo }}
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    fab
                    x-small
                    class="ml-1"
                    color="primary"
                  >
                    <v-icon @click="getDownloadFile(item)"
                      >arrow_downward</v-icon
                    >
                  </v-btn>
                </div>
              </v-row>
            </el-form>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <!-- 工作履历 -->
        <v-card flat>
          <v-card-title class="authentication-information"
            >工作履历</v-card-title
          >
          <v-divider class="ml-4 mr-4"></v-divider>
          <v-card-text class="text-content">
            <el-form
              :model="personalInformation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%;"
            >
              <v-row v-for="item in personalInformation.resumes" :key="item.id">
                <v-col cols="4">
                  <span>工作单位名称:</span>
                  {{ item.companyName }}
                </v-col>
                <v-col cols="4">
                  <span>职位:</span>
                  {{ item.jobTitle }}
                </v-col>
                <v-col cols="4">
                  <span>起止年月:</span>
                  {{ item.startDate | formatDate }} 至
                  {{ item.endDate | formatDate }}
                </v-col>
              </v-row>
            </el-form>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <!-- 学历 -->
        <v-card flat>
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1" text-color="#0c94ea">最高学历</el-menu-item>
            <el-menu-item index="2" v-if="twoEducation.sort"
              >第二学历</el-menu-item
            >
            <el-menu-item index="3" v-if="threeEducation.sort"
              >第三学历</el-menu-item
            >
            <el-menu-item index="4" v-if="fourEducation.sort"
              >第四学历</el-menu-item
            >
            <el-menu-item index="5" v-if="fiveEducation.sort"
              >第五学历</el-menu-item
            >
            <el-menu-item index="6" v-if="sixEducation.sort"
              >第六学历</el-menu-item
            >
          </el-menu>

          <v-card-text class="text-content" v-if="activeEducation === '1'">
            <el-form
              :model="oneEducation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%"
            >
              <v-row>
                <v-col cols="3">
                  <span>最高学历:</span>
                  {{ oneEducation.academic }}
                </v-col>
                <v-col cols="3">
                  <span>是否国外学历:</span>
                  <span
                    class="content"
                    v-html="oneEducation.isStudyAbroad === true ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业学校:</span>
                  {{ oneEducation.school }}
                </v-col>
                <v-col cols="3">
                  <span>专业:</span>
                  {{ oneEducation.major }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="3" v-if="!oneEducation.isStudyAbroad">
                  <span>是否全日制:</span>
                  <span
                    class="content"
                    v-html="oneEducation.type === '全日制' ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业时间:</span>
                  {{ oneEducation.graduateTime | formatDate }}
                </v-col>
                <v-col cols="3">
                  <span>学制(年):</span>
                  {{ oneEducation.schoolSystem }}
                </v-col>
                <v-col cols="3" v-if="!oneEducation.isStudyAbroad">
                  <span>毕业证书编号:</span>
                  {{ oneEducation.diplomaCode }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="card-height mt-6">
                <div
                  class="file"
                  v-for="item in oneEducationFile"
                  :key="item.id"
                >
                  <v-btn
                    depressed
                    class="see-file"
                    color="primary"
                    @click="getFile(item.path)"
                  >
                    {{ item.memo }}
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    fab
                    x-small
                    class="ml-1"
                    color="primary"
                    @click="getDownloadFile(item)"
                  >
                    <v-icon>arrow_downward</v-icon>
                  </v-btn>
                </div>
              </v-row>
            </el-form>
          </v-card-text>

          <v-card-text class="text-content" v-if="activeEducation === '2'">
            <el-form
              :model="twoEducation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%"
            >
              <v-row>
                <v-col cols="3">
                  <span>第二学历:</span>
                  {{ twoEducation.academic }}
                </v-col>
                <v-col cols="3">
                  <span>是否国外学历:</span>
                  <span
                    class="content"
                    v-html="twoEducation.isStudyAbroad === true ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业学校:</span>
                  {{ twoEducation.school }}
                </v-col>
                <v-col cols="3">
                  <span>专业:</span>
                  {{ twoEducation.major }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="3" v-if="!twoEducation.isStudyAbroad">
                  <span>是否全日制:</span>
                  <span
                    class="content"
                    v-html="twoEducation.type === '全日制' ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业时间:</span>
                  {{ twoEducation.graduateTime | formatDate }}
                </v-col>
                <v-col cols="3">
                  <span>学制(年):</span>
                  {{ twoEducation.schoolSystem }}
                </v-col>
                <v-col cols="3" v-if="!twoEducation.isStudyAbroad">
                  <span>毕业证书编号:</span>
                  {{ twoEducation.diplomaCode }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="card-height mt-6">
                <div
                  class="file"
                  v-for="item in twoEducation.files"
                  :key="item.id"
                >
                  <v-btn
                    depressed
                    class="see-file"
                    color="primary"
                    @click="getFile(item.path)"
                  >
                    {{ item.memo }}
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    fab
                    x-small
                    class="ml-1"
                    color="primary"
                    @click="getDownloadFile(item)"
                  >
                    <v-icon>arrow_downward</v-icon>
                  </v-btn>
                </div>
              </v-row>
            </el-form>
          </v-card-text>

          <v-card-text class="text-content" v-if="activeEducation === '3'">
            <el-form
              :model="threeEducation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%"
            >
              <v-row>
                <v-col cols="3">
                  <span>第三学历:</span>
                  {{ threeEducation.academic }}
                </v-col>
                <v-col cols="3">
                  <span>是否国外学历:</span>
                  <span
                    class="content"
                    v-html="threeEducation.isStudyAbroad === true ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业学校:</span>
                  {{ threeEducation.school }}
                </v-col>
                <v-col cols="3">
                  <span>专业:</span>
                  {{ threeEducation.major }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="3" v-if="!threeEducation.isStudyAbroad">
                  <span>是否全日制:</span>
                  <span
                    class="content"
                    v-html="threeEducation.type === '全日制' ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业时间:</span>
                  {{ threeEducation.graduateTime | formatDate }}
                </v-col>
                <v-col cols="3">
                  <span>学制(年):</span>
                  {{ threeEducation.schoolSystem }}
                </v-col>
                <v-col cols="3" v-if="!threeEducation.isStudyAbroad">
                  <span>毕业证书编号:</span>
                  {{ threeEducation.diplomaCode }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="card-height mt-6">
                <div
                  class="file"
                  v-for="item in threeEducation.files"
                  :key="item.id"
                >
                  <v-btn
                    depressed
                    class="see-file"
                    color="primary"
                    @click="getFile(item.path)"
                  >
                    {{ item.memo }}
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    fab
                    x-small
                    class="ml-1"
                    color="primary"
                    @click="getDownloadFile(item)"
                  >
                    <v-icon>arrow_downward</v-icon>
                  </v-btn>
                </div>
              </v-row>
            </el-form>
          </v-card-text>

          <v-card-text class="text-content" v-if="activeEducation === '4'">
            <el-form
              :model="fourEducation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%"
            >
              <v-row>
                <v-col cols="3">
                  <span>第四学历:</span>
                  {{ fourEducation.academic }}
                </v-col>
                <v-col cols="3">
                  <span>是否国外学历:</span>
                  <span
                    class="content"
                    v-html="fourEducation.isStudyAbroad === true ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业学校:</span>
                  {{ fourEducation.school }}
                </v-col>
                <v-col cols="3">
                  <span>专业:</span>
                  {{ fourEducation.major }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="3" v-if="!fourEducation.isStudyAbroad">
                  <span>是否全日制:</span>
                  <span
                    class="content"
                    v-html="fourEducation.type === '全日制' ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业时间:</span>
                  {{ fourEducation.graduateTime | formatDate }}
                </v-col>
                <v-col cols="3">
                  <span>学制(年):</span>
                  {{ fourEducation.schoolSystem }}
                </v-col>
                <v-col cols="3" v-if="!fourEducation.isStudyAbroad">
                  <span>毕业证书编号:</span>
                  {{ fourEducation.diplomaCode }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="card-height mt-6">
                <div
                  class="file"
                  v-for="item in fourEducation.files"
                  :key="item.id"
                >
                  <v-btn
                    depressed
                    class="see-file"
                    color="primary"
                    @click="getFile(item.path)"
                  >
                    {{ item.memo }}
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    fab
                    x-small
                    class="ml-1"
                    color="primary"
                    @click="getDownloadFile(item)"
                  >
                    <v-icon>arrow_downward</v-icon>
                  </v-btn>
                </div>
              </v-row>
            </el-form>
          </v-card-text>

          <v-card-text class="text-content" v-if="activeEducation === '5'">
            <el-form
              :model="fiveEducation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%"
            >
              <v-row>
                <v-col cols="3">
                  <span>第五学历:</span>
                  {{ fiveEducation.academic }}
                </v-col>
                <v-col cols="3">
                  <span>是否国外学历:</span>
                  <span
                    class="content"
                    v-html="fiveEducation.isStudyAbroad === true ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业学校:</span>
                  {{ fiveEducation.school }}
                </v-col>
                <v-col cols="3">
                  <span>专业:</span>
                  {{ fiveEducation.major }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="3" v-if="!fiveEducation.isStudyAbroad">
                  <span>是否全日制:</span>
                  <span
                    class="content"
                    v-html="fiveEducation.type === '全日制' ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业时间:</span>
                  {{ fiveEducation.graduateTime | formatDate }}
                </v-col>
                <v-col cols="3">
                  <span>学制(年):</span>
                  {{ fiveEducation.schoolSystem }}
                </v-col>
                <v-col cols="3" v-if="!fiveEducation.isStudyAbroad">
                  <span>毕业证书编号:</span>
                  {{ fiveEducation.diplomaCode }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="card-height mt-6">
                <div
                  class="file"
                  v-for="item in fiveEducation.files"
                  :key="item.id"
                >
                  <v-btn
                    depressed
                    class="see-file"
                    color="primary"
                    @click="getFile(item.path)"
                  >
                    {{ item.memo }}
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    fab
                    x-small
                    class="ml-1"
                    color="primary"
                    @click="getDownloadFile(item)"
                  >
                    <v-icon>arrow_downward</v-icon>
                  </v-btn>
                </div>
              </v-row>
            </el-form>
          </v-card-text>

          <v-card-text class="text-content" v-if="activeEducation === '6'">
            <el-form
              :model="sixEducation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%"
            >
              <v-row>
                <v-col cols="3">
                  <span>第六学历:</span>
                  {{ sixEducation.academic }}
                </v-col>
                <v-col cols="3">
                  <span>是否国外学历:</span>
                  <span
                    class="content"
                    v-html="sixEducation.isStudyAbroad === true ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业学校:</span>
                  {{ sixEducation.school }}
                </v-col>
                <v-col cols="3">
                  <span>专业:</span>
                  {{ sixEducation.major }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="3" v-if="!sixEducation.isStudyAbroad">
                  <span>是否全日制:</span>
                  <span
                    class="content"
                    v-html="sixEducation.type === '全日制' ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业时间:</span>
                  {{ sixEducation.graduateTime | formatDate }}
                </v-col>
                <v-col cols="3">
                  <span>学制(年):</span>
                  {{ sixEducation.schoolSystem }}
                </v-col>
                <v-col cols="3" v-if="!sixEducation.isStudyAbroad">
                  <span>毕业证书编号:</span>
                  {{ sixEducation.diplomaCode }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="card-height mt-6">
                <div
                  class="file"
                  v-for="item in sixEducation.files"
                  :key="item.id"
                >
                  <v-btn
                    depressed
                    class="see-file"
                    color="primary"
                    @click="getFile(item.path)"
                  >
                    {{ item.memo }}
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    fab
                    x-small
                    class="ml-1"
                    color="primary"
                    @click="getDownloadFile(item)"
                  >
                    <v-icon>arrow_downward</v-icon>
                  </v-btn>
                </div>
              </v-row>
            </el-form>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <!-- 个人简历 -->
        <v-card flat>
          <v-card-title class="authentication-resume">个人简历</v-card-title>
          <v-card-text class="text-content">
            <v-row style="width: 100%;text-align: left">
              <v-col cols="12">
                <div class="policy-resume">
                  {{ personalInformation.resume }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <!-- 人才类型 -->
        <v-card flat>
          <v-card-title>
            <el-form
              :model="sixEducation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%"
            >
              <v-row>
                <v-col cols="4">
                  <el-form-item label="人才类型">
                    <el-select
                      disabled
                      v-model="personalInformation.type"
                      placeholder="请选择"
                      filterable
                    >
                      <el-option
                        v-for="item in talentType"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </v-col>
                <v-col cols="4">
                  <el-form-item
                    v-for="item in personnelTypeFile"
                    :key="item.id"
                  >
                    <v-btn
                      depressed
                      class="see-file"
                      color="primary"
                      @click="getFile(item.path)"
                    >
                      查看{{ item.memo }}
                    </v-btn>
                  </el-form-item>
                </v-col>
              </v-row>
            </el-form>
          </v-card-title>
        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card flat>
          <v-row class="card-height pt-4">
            <div class="id-file" v-for="item in idPhotos" :key="item.id">
              <v-img
                :src="item.path"
                :aspect-ratio="1"
                class="id-photo"
                contain
                alt="加载失败"
                @click="getIdFile(item.path)"
              ></v-img>
              <v-btn
                outlined
                rounded
                fab
                x-small
                class="ml-1 down"
                color="primary"
                @click="getDownloadFile(item)"
              >
                <v-icon>arrow_downward</v-icon>
              </v-btn>
            </div>
            <div class="file mt-4" v-for="item in wordFile" :key="item.id">
              <v-btn
                depressed
                class="see-file"
                color="primary"
                @click="getFile(item.path)"
              >
                {{ item.memo }}
              </v-btn>
              <v-btn outlined rounded fab x-small class="ml-1" color="primary">
                <v-icon @click="getDownloadFile(item)">arrow_downward</v-icon>
              </v-btn>
            </div>
          </v-row>
        </v-card>

        <div class="mt-4"></div>

        <v-card flat>
          <v-card-text class="text-content">
            <span>缴纳社保时间: </span>
            <span class="content">{{
              personalInformation.socialSecurityPaymentTime | formatDate
            }}</span>
          </v-card-text>
          <v-divider class="ml-4 mr-4"></v-divider>
          <v-card-text class="text-content">
            <span>是否在赣州经开区缴纳社保: </span>
            <span
              class="content"
              v-html="
                personalInformation.hasSocialSecurity === true ? '是' : '否'
              "
            ></span>
          </v-card-text>
          <v-divider class="ml-4 mr-4"></v-divider>
          <v-card-text class="text-content">
            <span>是否买房: </span>
            <span
              class="content"
              v-html="personalInformation.buyHouse === true ? '是' : '否'"
            ></span>
          </v-card-text>
          <v-divider class="ml-4 mr-4"></v-divider>
          <v-card-text class="text-content">
            <span>是否在中心城区有房: </span>
            {{ personalInformation.block }}
          </v-card-text>
          <v-card-text class="card-height">
            <div class="file" v-for="item in socialSecurityFile" :key="item.id">
              <v-btn
                depressed
                class="see-file"
                color="primary"
                @click="getFile(item.path)"
              >
                {{ item.memo }}
              </v-btn>
              <v-btn outlined rounded fab x-small class="ml-1" color="primary">
                <v-icon @click="getDownloadFile(item)">arrow_downward</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <v-card flat>
          <div class="honor">
            所获荣誉 ({{ honorNumber }}个)
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn color="#0c94ea" fab text small v-on="on" class="fr mt-1">
                  <v-icon color="#FFFFFF">keyboard_arrow_down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="item in items" :key="item.id">
                  <v-list-item-action @click="selectCertificate(item)">
                    {{ item.name }}
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div>
            <v-card-text class="text-content">
              <span>所获荣誉名称:</span>
              {{ honor.honorName }}
            </v-card-text>
            <v-divider class="ml-4 mr-4"></v-divider>
            <v-card-text class="text-content">
              <span>实施部门:</span>
              {{ honor.implementationDepartment }}
            </v-card-text>
            <v-divider class="ml-4 mr-4"></v-divider>
            <v-card-text class="text-content">
              <span>获奖年月:</span>
              <span
                class="content"
                v-html="
                  honor.awardYear
                    ? honor.awardYear.substring(0, 10)
                    : honor.awardYear
                "
              ></span>
            </v-card-text>
            <v-divider class="ml-4 mr-4"></v-divider>
            <v-card-text class="text-content">
              <span>所获奖励级别:</span>
              {{ honor.rewardLevel }}
            </v-card-text>
            <v-row class="card-height mt-2">
              <div
                class="file"
                v-for="fileItem in honor.files"
                :key="fileItem.id"
              >
                <v-btn
                  depressed
                  class="see-file"
                  color="primary"
                  @click="getFile(fileItem.path)"
                >
                  {{ fileItem.memo }}
                </v-btn>
                <v-btn
                  outlined
                  rounded
                  fab
                  x-small
                  class="ml-1"
                  color="primary"
                >
                  <v-icon @click="getDownloadFile(fileItem)"
                    >arrow_downward</v-icon
                  >
                </v-btn>
              </div>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <div style="margin: 24px 0 32px 0">
      <el-row v-if="personalInformation.status === 2">
        <el-col :span="3" :offset="7">
          <el-button type="success" class="text" @click="review(11)"
            >申请通过</el-button
          >
        </el-col>
        <el-col :span="3">
          <el-button type="warning" class="text" @click="review(10)"
            >驳回申请</el-button
          >
        </el-col>
      </el-row>
      <el-row v-else>
        <el-col>
          <el-tag type="info" v-if="personalInformation.status === 10"
            >已驳回</el-tag
          >
          <el-tag type="success" v-else>已通过</el-tag>
        </el-col>
      </el-row>
    </div>

    <el-dialog title="驳回理由" :visible.sync="dialogVisible" width="520px">
      <el-input
        type="textarea"
        :rows="5"
        v-model="content"
        placeholder="请输入驳回理由"
        maxlength="50"
      ></el-input>
      <div style="font-size: 12px;float: left;overflow: hidden">
        字数不超过50字
      </div>
      <div style="margin-top: 40px"></div>
      <el-button type="primary" @click="requestReject">确 定</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </el-dialog>
    <el-dialog
      title="预览图片"
      :visible.sync="seePhoto"
      :width="oWidth"
      style="margin-top: -32px"
    >
      <v-img :src="addressId" alt="" v-if="addressId" />
      <iframe
        :src="pdf"
        frameborder="0"
        style="width: 100%; height: 700px"
        v-if="pdf"
      ></iframe>
      <el-button type="primary" @click="seePhoto = false">关 闭</el-button>
    </el-dialog>
  </div>
</template>

<script>
//import headtitle from "@/components/ControlPanel/headtitle";
import configDate from "../../../utils/config";

export default {
  components: {
    //headtitle
  },
  data() {
    return {
      pdf: "",
      oWidth: "560px",
      personalInformation: {
        address: "", // 编辑时的回显地址
        addressed: "", // 办理时的回显地址
        addresses: [], // 选择时的地址
        addressId: [], // 地址id
        details: "", // 详细地址
        birthday: "", // 出生年月
        birthplace: "", // 籍贯
        block: "", // 住房所在区
        buyHouse: false, // 是否买房
        citizenship: "", // 国籍
        email: "", // 邮箱
        endEffectiveDate: "", // 证件有效结束日期
        enterpriseName: "", // 目前就职公司
        enterpriseId: "", // 目前就职公司id
        files: [], // 上传的文件信息
        fileIds: [], // 上传文件的id
        hasHouse: false, // 在城中心是否有房
        hasSocialSecurity: false, // 是否缴纳有社保
        honors: [], // 所获荣誉
        id: 0,
        identityCode: 0, // 证件号码
        identityType: 0, // 证件类型
        memo: "", // 驳回人才备注
        name: "",
        nation: "", //民族
        passportExpireDate: "", // 护照到期时间
        passportNumber: "", // 护照号码
        phone: "", // 手机号码
        resume: "", // 个人简历
        resumes: [], // 工作履历
        sex: 0, // 性别
        socialSecurityPaymentTime: "", // 社保缴纳时间
        startEffectiveDate: "", // 证件有效开始日期
        status: 0, // 状态
        talentEducations: [], // 教育信息
        talentOccupation: [], // 职业信息
        occupation: {}, // 目前工作
        type: "", // 人才类型
        clobs: [], // 人才备注
      },
      /*
            人才类型
             */
      talentType: [
        { name: "A+类（产业领军人才）", id: "value1" },
        { name: "A类（国内外顶尖人才 ）", id: "value2" },
        { name: "B类（国家级高层次人才）", id: "value3" },
        { name: "C类（省级高层次人才 ）", id: "value4" },
        { name: "D类（市级高层次人才）", id: "value5" },
        { name: "E类（行业急需紧缺人才）", id: "value6" },
        { name: "F类（青年英才）", id: "value7" },
        { name: "G类（产业工匠）", id: "value8" },
      ],
      diplomaFile: [], // 最高学历证书附件
      academicRecordFormFile: [], // 最高学历备案表附件
      academicDegreeFile: [], // 最高学位附件
      socialSecurityFile: [], // 社保附件
      personnelTypeFile: [], // 人才类型附件
      wordFile: [], // 免冠工作照
      laborContractFile: [], // 劳动合同
      certificateHonorFile: [], // 荣誉证书
      individualIncomeTax: [], // 个人所得税
      oneEducationFile: [], // 第一学历
      personnelFiles: [],
      idPhotos: [], // 身份证照片
      items: [], //选择所获荣誉
      activeIndex: "1",
      activeEducation: "1",
      oneEducation: {},
      twoEducation: {},
      threeEducation: {},
      fourEducation: {},
      fiveEducation: {},
      sixEducation: {},
      honor: {},
      clobs: { content: "" },
      content: "",
      loading: true,
      seePhoto: false,
      dialogVisible: false,
      addressId: "",
      honorNumber: "",
      Ids: "",
      handlerId: "",
    };
  },
  created() {
    this.Ids = this.$route.params.id;
    this.handlerId = sessionStorage.getItem("ID");
    this.getDate();
  },
  methods: {
    getDate() {
      let self = this;
      this.$axios
        .get("/talent/detail?id=" + this.Ids)
        .then(function(response) {
          self.personalInformation = response.data.data;
          self.personalInformation.enterpriseId =
            response.data.data.talentOccupation.enterpriseId;
          self.personalInformation.phone = response.data.data.account.contact;
          self.personalInformation.addressId = response.data.data.address.id;
          self.personalInformation.details = response.data.data.address.details;
          self.personalInformation.addressed =
            response.data.data.address.province +
            response.data.data.address.city +
            response.data.data.address.county +
            response.data.data.address.details;
          self.personalInformation.address =
            "中国" +
            " / " +
            response.data.data.address.province +
            " / " +
            response.data.data.address.city +
            " / " +
            response.data.data.address.county;
          if (
            self.personalInformation.educations instanceof Array &&
            self.personalInformation.educations.length > 0
          ) {
            for (
              let i = 0;
              i < self.personalInformation.educations.length - 1;
              i++
            ) {
              // 内层循环,控制比较的次数，并且判断两个数的大小
              for (
                let j = 0;
                j < self.personalInformation.educations.length - 1 - i;
                j++
              ) {
                // 白话解释：如果前面的数大，放到后面(当然是从小到大的冒泡排序)
                if (
                  self.personalInformation.educations[j].sort >
                  self.personalInformation.educations[j + 1].sort
                ) {
                  const temp = self.personalInformation.educations[j];
                  self.personalInformation.educations[j] =
                    self.personalInformation.educations[j + 1];
                  self.personalInformation.educations[j + 1] = temp;
                }
              }
            }
          }
          self.personalInformation.educations.forEach((item, index) => {
            switch (index) {
              case 0:
                self.oneEducation = item;
                break;
              case 1:
                self.twoEducation = item;
                break;
              case 2:
                self.threeEducation = item;
                break;
              case 3:
                self.fourEducation = item;
                break;
              case 4:
                self.fiveEducation = item;
                break;
              case 5:
                self.sixEducation = item;
                break;
            }
          });
          self.diplomaFile = []; // 最高学历
          self.academicRecordFormFile = []; // 最高学历备案表
          self.academicDegreeFile = []; // 最高学位
          self.idPhotos = []; //身份证
          self.socialSecurityFile = []; // 社保附件
          self.personnelTypeFile = []; // 人才类型
          self.wordFile = []; // 免冠工作照
          self.laborContractFile = []; // 劳动合同
          self.oneEducationFile = []; // 第一学历文件
          self.individualIncomeTax = []; // 个人所得税
          self.personalInformation.fileIds = [];
          console.log(response.data.data.files);
          for (let i = 0; i < response.data.data.files.length; i++) {
            let item = response.data.data.files[i];
            //console.log(i);
            self.personalInformation.fileIds.push(item.id);
            switch (item.memo) {
              case "人才类型附件":
                self.personnelTypeFile.push(item);
                console.log(self.personnelTypeFile);
                break;
              case "免冠工作照":
                self.wordFile.push(item);
                break;
              case "身份证":
                self.idPhotos.push(item);
                break;
              case "社保附件":
                self.socialSecurityFile.push(item);
                break;
              case "最高学历证书":
                self.diplomaFile.push(item);
                self.oneEducationFile.push(item);
                break;
              case "最高学历电子信息备案表":
                self.academicRecordFormFile.push(item);
                self.oneEducationFile.push(item);
                break;
              case "最高学位证书":
                self.academicDegreeFile.push(item);
                self.oneEducationFile.push(item);
                break;
              case "劳动合同":
                self.laborContractFile.push(item);
                break;
              case "个人所得税":
                self.individualIncomeTax.push(item);
                break;
              case "荣誉证书":
                console.log(response.data.data.files[i])
                //self.honorFile.push(response.data.data.files[i]);
                break;
              default:
                break;
            }
          }
          self.idPhotos.forEach((item) => {
            item.path = configDate.fileurl + item.path;
          });
          self.items = [];
          self.personalInformation.honors.forEach((item, index) => {
            if (index === 0) {
              self.honor = item;
            }
            self.items.push({
              name: item.honorName,
              id: item.id,
            });
          });
          if (!!response.data.data.clobs) {
            response.data.data.clobs.forEach((item, index) => {
              if (index === 0) {
                self.clobs = item;
              }
            });
          }
          if (self.personalInformation.honors) {
            self.honorNumber = self.personalInformation.honors.length;
          }
          self.personalInformation.resumes.forEach((item) => {
            item.displayMenuResumeStartDate = false;
            item.displayMenuResumeEndDate = false;
            item.resumeStartDate = item.startDate;
            item.resumeEndDate = item.endDate;
          });
          self.personalInformation.educations.forEach((item) => {
            item.diplomaFile = []; // 学历证书附件
            item.academicRecordFormFile = []; // 学历备案表附件
            item.academicDegreeFile = []; // 学位证书
            item.files.forEach((fileItem) => {
              switch (fileItem.memo) {
                case "学历证书":
                  item.diplomaFile.push({
                    id: fileItem.id,
                    name: fileItem.name,
                    url: configDate.fileurl + fileItem.path,
                  });
                  break;
                case "学历电子信息备案表":
                  item.academicRecordFormFile.push({
                    id: fileItem.id,
                    name: fileItem.name,
                    url: configDate.fileurl + fileItem.path,
                  });
                  break;
                case "学位证书":
                  item.academicDegreeFile.push({
                    id: fileItem.id,
                    name: fileItem.name,
                    url: configDate.fileurl + fileItem.path,
                  });
                  break;
              }
            });
          });
        })
        .catch(function(error) {
          console.error(error);
        });
    },

    // 点击选择查看荣誉证书
    selectCertificate(item) {
      this.personalInformation.honors.forEach((honorItem) => {
        if (item.id === honorItem.id) {
          this.honor = honorItem;
        }
      });
    },

    // 学历
    handleSelect(key) {
      this.activeEducation = key;
    },

    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },
    back() {
      this.$router.push({ path: "/examinelist" });
    },

    //下载
    downloaded(path) {
      // window.location.href = this.fileurl + path;
      this.seePhoto = true;
      this.addressId = this.fileurl + path;
    },

    //  驳回
    requestReject() {
      let self = this;
      this.$axios
        .put("/talent", {
          id: self.Ids,
          memo: self.content,
          status: 10,
        })
        .then((response) => {
          if (response.data.status === 200) {
            self.$notify({
              title: "成功",
              message: "办理成功",
              type: "success",
            });
            self.dialogVisible = false;
            self.$router.push("/examinelist");
          } else {
            self.$notify({
              title: "失败",
              message: "办理失败",
              type: "success",
            });
            self.dialogVisible = false;
          }
        })
        .catch((error) => {
          self.dialogVisible = false;
          console.error(error);
        });
    },

    //审核
    review(num) {
      if (num === 10) {
        this.dialogVisible = true;
        return false;
      }
      let self = this;
      let stex = num === 11 ? "确定要申请通过吗?" : "确定要驳回申请吗?";
      this.$confirm(stex, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .put("/talent/check", {
              handlerId: self.handlerId,
              status: num,
              talentId: self.Ids,
            })
            .then(function(response) {
              if (response.data.status === 200) {
                self.$root.success("办理成功");
                self.$router.push("/examinelist");
              } else {
                self.$root.warn("处理失败");
              }
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch(() => {
          self.$root.warn("取消办理");
        });
    },

    // 显示身份证
    getIdFile(path) {
      this.seePhoto = true;
      this.addressId = path;
      this.pdf = "";
      this.oWidth = "500px";
    },

    //  查看文件
    getFile(path) {
      // this.seePhoto = true;
      // this.addressId = configDate.fileurl + path
      this.seePhoto = true;
      let pdfFile = path.split(".");
      if (pdfFile[1] === "pdf") {
        //window.open(configDate.pdffileurl + path,"_blank");
        this.pdf = configDate.pdffileurl + path;
        this.addressId = "";

        this.oWidth = "70%";
      } else {
        this.pdf = "";
        this.oWidth = "900px";
        this.addressId = configDate.fileurl + path;
      }
    },

    // 下载文件
    getDownloadFile(item) {
      let path =
        item.memo === "身份证" ? item.path : configDate.fileurl + item.path;
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = path;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // 控制显示的内容
    getOccupation(id) {
      let self = this;
      this.$axios
        .get("/enterprise", {
          params: {
            id: id,
          },
        })
        .then(function(response) {
          if (response.data.status === 200) {
            self.ename = response.data.data.name;
          } else {
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handleEdit(index, row) {},
  },

  computed: {
    // 控制显示的内容
    iSBoolean() {
      return function(value1, value2) {
        return value1 === value2;
      };
    },
  },
};
</script>

<style lang="less" scoped>
* {
  color: #000000;
}

.text-content {
  text-align: left;
  margin: 0 8px;
  color: #000000;
  font-size: 16px;

  span {
    color: #8a8f97;
  }

  .content {
    color: #2a3746;
  }
}

.card-height {
  margin: 0 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  .id-file {
    display: inline-block;
    min-width: 160px;
    min-height: 48px;
    margin: 0 8px;

    .id-photo {
      width: 120px;
      display: inline-block;
    }

    .down {
      float: right;
      margin-top: 48px;
    }
  }

  .file {
    min-width: 240px;
    height: 48px;

    .see-file {
      min-width: 160px;
    }
  }

  .file-card {
    display: inline-block;
    vertical-align: top;
    width: 256px;
  }

  .upload-demo {
    width: 240px;
    border: 1px solid #0c94ea;
    border-radius: 8px;
    padding: 8px;

    .file-button {
      color: #0c94ea;
    }

    .file-name {
      text-align: left;

      span {
        color: red;
      }
    }
  }
}

.authentication-resume {
  color: #0c94ea;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  height: 48px;
  padding-top: 2px;
  line-height: 48px;
}

.authentication-information {
  color: #0c94ea;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  height: 48px;
  margin-left: 24px;
  padding-top: 2px;
  line-height: 48px;
}

.honor {
  width: 100%;
  height: 48px;
  font-weight: bold;
  padding: 0 8px;
  color: #ffffff;
  line-height: 48px;
  font-size: 16px;
  background-color: #0c94ea;
  text-align: left;
}

.talents-type {
  text-align: left;

  .remarks {
    background-color: #f5f5f5;
    border: 1px #8a8f97 dashed;
  }
}

.headtit {
  background: #1090e7;
  height: 50px;
  text-align: left;
  line-height: 50px;
  padding: 0 0 0 16px;
  color: #fff;
  font-size: 16px;
}

.buttbut {
  overflow: hidden;
  width: 500px;
  margin: 0 auto;
}

.ChaceSaa {
  border-bottom: 1px #ddd dashed;
  padding: 10px 0;
}

.basicCoxs {
  overflow: hidden;
  //
  text-align: left;

  .cailxx {
    span {
      margin: 1px;
    }
  }

  .fujianx {
    span {
      width: 35%;
      float: left;
      background: #fff;
      border: 1px #1090e7 solid;
      margin: 8px;
      text-align: center;
      cursor: pointer;
    }
  }

  span {
    background: #f7f7f8;
    font-size: 14px;
    display: block;
    height: 35px;
    text-align: left;
    padding: 0 16px;
    line-height: 33px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
    margin: 4px 8px;
    min-width: 280px;
    border: 1px solid #e4e4e4;
  }
}

.size-div {
  font-size: 14px;
  margin: 16px 0 0 12px;
}
.text {
  color: #ffffff;
}
</style>
